export function Shield() {
  return (
    <svg
      viewBox="0 0 192 154.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "100vh", position: "absolute", left: "50%" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.738595 74.407C-0.246199 76.134 -0.246198 78.2526 0.738596 79.9796L41.5485 151.548C42.5493 153.303 44.4145 154.387 46.4349 154.387H64.0423H127.934H145.542C147.562 154.387 149.427 153.303 150.428 151.548L191.238 79.9796C192.223 78.2526 192.223 76.134 191.238 74.4069L150.428 2.83866C149.427 1.08355 147.562 -5.75317e-06 145.542 -5.66431e-06L127.937 -4.89001e-06C127.936 -5.40624e-06 127.935 -5.66435e-06 127.934 -5.66431e-06L64.0423 -2.07973e-06H46.4348C44.4144 -1.99087e-06 42.5492 1.08355 41.5484 2.83866L0.738595 74.407Z"
        fill="#030083"
      ></path>
    </svg>
  );
}
